// Import any other modules or utilities you need
// import { someUtility } from './utilities';
import Auth from './auth';

interface HeyGGConfig {
  apiBaseUrl: string;
}

const defaultConfig: HeyGGConfig = {
  apiBaseUrl: 'https://play.hey.gg',
};

const config = { ...defaultConfig };

const setConfig = (newConfig: Partial<HeyGGConfig>) => {
  window.HeyGoodGame.config = { ...window.HeyGoodGame.config, ...newConfig };
};

// Export everything you want to make available globally
export { Auth, config, setConfig, toggleSidebar };

declare global {
  interface Window {
    HeyGoodGame: {
      Auth: typeof Auth;
      config: HeyGGConfig;
      setConfig: (newConfig: Partial<HeyGGConfig>) => void;
      toggleSidebar: () => void;
    };
  }
}

function checkAuthStatus() {
  Auth.getAuthStatus()
    .then((data) => {
      if (data.user) {
        const loginElement = document.getElementById('log-in');
        if (loginElement) loginElement.style.display = 'none';

        const signUpElement = document.getElementById('sign-up');
        if (signUpElement) signUpElement.style.display = 'none';

        const username = document.querySelector('span#username');
        if (username) username.textContent = data.user.name;

        const streak = document.querySelector('span#current-play-streak');
        if (streak) streak.textContent = data.user.current_play_streak;
      } else {
        console.log('Not logged in!');

        const logInLink = document.getElementById(
          'log-in-link',
        ) as HTMLAnchorElement;
        if (logInLink)
          logInLink.href =
            'https://play.hey.gg/login?redirect_uri=' + window.location.href;

        const signUpLink = document.getElementById(
          'sign-up-link',
        ) as HTMLAnchorElement;
        if (signUpLink)
          signUpLink.href =
            'https://play.hey.gg/register?redirect_uri=' + window.location.href;

        const logOutLink = document.getElementById(
          'logout-button',
        ) as HTMLAnchorElement;
        if (logOutLink)
          logOutLink.href =
            'https://id-beta.hey.gg/sso/logout?redirect_uri=' + window.location.href;
      }
    })
    .catch((error) => console.error('Authentication error:', error));
}

document.addEventListener('DOMContentLoaded', function () {
  checkAuthStatus();
});

const toggleSidebar = () => {
  const sidebarElement = document.getElementById('sidebar');
  if (sidebarElement) {
    sidebarElement.classList.toggle('sidebar-active');
  }
};

window.HeyGoodGame = {
  Auth,
  config,
  setConfig,
  toggleSidebar,
};
